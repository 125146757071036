<template>
  <div class="page-opticians animate__animated animate__fadeIn pt-4 pb-2">
    <img
      :src="require('@/assets/banners/update-loyalties.png')"
      class="w-100 mb-4"
      alt=""
      style="display: none"
      @load="
        (e) => {
          e.target.style.display = 'block';
          hideSpinner('spinner-banner');
        }
      "
    />

    <div id="spinner-banner" class="text-center my-5">
      <img src="/assets/img/spinner-loading.gif" width="100px" alt="" />
    </div>
    <h5 class="fs-la">Which opticians cards would you like to update?</h5>
    <p class="fs-sm">Select as many providers as you like</p>

    <!-- Opticians -->
    <div class="row">
      <div
        class="col-4 col-sm-3"
        v-for="(item, index) in opticians"
        :key="index"
        style="padding: 4px 2px"
      >
        <div
          :class="`d-flex align-items-center justify-content-center m-1 p-1 squares h-100 hvr hvr-radial-out ${
            selectedOpticians.includes(item) ? 'selected' : ''
          }`"
          @click="selectProvider(item)"
        >
          <div>
            <img
              :src="`${urlBucket}/${item.icon}`"
              @error="(e) => (e.target.src = `${urlBucket}/form/assets/Opticians.png`)"
              class="img-fluid"
              style="display: none"
              @load="
                (e) => {
                  e.target.style.display = 'unset';
                  hideSpinner(`spinner-${index}`);
                }
              "
            />
            <img src="/assets/img/spinner-loading.gif" :id="`spinner-${index}`" class="img-fluid" alt="" width="65px" />
            <p class="fw-bold mb-0">{{ item.name }}</p>
          </div>
        </div>
      </div>
    </div>
    <p class="mb-0 mt-2" v-if="errors.opticians" style="color: #c64a45">* You must select an option</p>

  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { opticians } from "@/data/lists";
import { navigateTo } from "../router/index.js";
// @ is an alias to /src

export default {
  name: "PageOpticians",
  components: {},
  data() {
    return {
      opticians,
      errors:{
        opticians:false
      }
    };
  },
  methods: {
    hideSpinner(id) {
      document.getElementById(id).style.display = "none";
    },
    selectProvider(value) {
      let array = this.selectedOpticians;

      if (this.selectedOpticians.includes(value)) {
        let index = array.indexOf(value);
        array.splice(index, 1);
      } else {
        array.push(value);
      }

      this.$store.commit("formStore/setField", [`selectedOpticians`, array]);
    },
    validateInputs() {
      //opticians
      if (this.selectedOpticians.length == 0) {
        this.errors.opticians = true;
      } else {
        this.errors.opticians = false;
      }
    },
  },
  mounted() {
    this.$store.commit("setField", ["titleNavbar", "Your opticians"]);

    const validatePage = () => {
      let route = "/about-you";

      // const setRoute = () => {
      //   if (this.informBanks) {
      //     route = "/banks";
      //     return;
      //   }
      // };

      // setRoute();

      // this.validateInputs();

      if (!Object.values(this.errors).includes(true)) {
        navigateTo(route);
      }
    };

    document.getElementById("mainButton").onclick = (ev) => {
      validatePage();
    };

    document.onkeydown = (e) => {
      if (e.key == "ArrowLeft" && document.activeElement.tagName !== "INPUT") {
        this.$router.back();
      }

      if (e.key == "ArrowRight" && document.activeElement.tagName !== "INPUT") {
        validatePage();
      }
    };
  },

  computed: {
    ...mapState([""]),
    ...mapState("formStore", [
      "referrer",
      "baseURL",
      "urlBucket",
      "informBanks",
      "informOpticians",
      "selectedOpticians"
    ]),
    ...mapGetters("formStore", ["setRoute"]),
  },
};
</script>
<style lang="scss">
.page-opticians {
  .squares {
    text-align: center;
    background: #fff;
    border-radius: 5px;
    cursor: pointer;
    color: black;
    box-shadow: 0 1px 7px #0b2d9633;

    p {
      font-size: 13px;
    }
    /*
   img {
      width: 60px;
      height: 55px;
    }
   */
  }

  .selected {
    background-color: #1cb0f6;
    color: white !important;
  }
}
</style>

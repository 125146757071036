var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-opticians animate__animated animate__fadeIn pt-4 pb-2"},[_c('img',{staticClass:"w-100 mb-4",staticStyle:{"display":"none"},attrs:{"src":require('@/assets/banners/update-loyalties.png'),"alt":""},on:{"load":(e) => {
        e.target.style.display = 'block';
        _vm.hideSpinner('spinner-banner');
      }}}),_vm._m(0),_c('h5',{staticClass:"fs-la"},[_vm._v("Which opticians cards would you like to update?")]),_c('p',{staticClass:"fs-sm"},[_vm._v("Select as many providers as you like")]),_c('div',{staticClass:"row"},_vm._l((_vm.opticians),function(item,index){return _c('div',{key:index,staticClass:"col-4 col-sm-3",staticStyle:{"padding":"4px 2px"}},[_c('div',{class:`d-flex align-items-center justify-content-center m-1 p-1 squares h-100 hvr hvr-radial-out ${
          _vm.selectedOpticians.includes(item) ? 'selected' : ''
        }`,on:{"click":function($event){return _vm.selectProvider(item)}}},[_c('div',[_c('img',{staticClass:"img-fluid",staticStyle:{"display":"none"},attrs:{"src":`${_vm.urlBucket}/${item.icon}`},on:{"error":(e) => (e.target.src = `${_vm.urlBucket}/form/assets/Opticians.png`),"load":(e) => {
                e.target.style.display = 'unset';
                _vm.hideSpinner(`spinner-${index}`);
              }}}),_c('img',{staticClass:"img-fluid",attrs:{"src":"/assets/img/spinner-loading.gif","id":`spinner-${index}`,"alt":"","width":"65px"}}),_c('p',{staticClass:"fw-bold mb-0"},[_vm._v(_vm._s(item.name))])])])])}),0),(_vm.errors.opticians)?_c('p',{staticClass:"mb-0 mt-2",staticStyle:{"color":"#c64a45"}},[_vm._v("* You must select an option")]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center my-5",attrs:{"id":"spinner-banner"}},[_c('img',{attrs:{"src":"/assets/img/spinner-loading.gif","width":"100px","alt":""}})])
}]

export { render, staticRenderFns }